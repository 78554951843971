<template>
  <div class="createForm">
    <vs-dialog prevent-close not-close v-model="modalShow">
      <template #header>
        <h4 class="not-margin">
          Quién tipo de usuario eres ?
        </h4>
      </template>

      <b-card-group class="modalBody">
        <div class="divCardItem">
          <vs-card @click="closeModal(1)" type="4">
            <template #title>
              <h3>USUARIO</h3>
            </template>
            <template #text>
              <p>
                Usuario o influencer que quiere pasarselo bien
              </p>
            </template>
            <template #img>
              <iPeople />
            </template>
          </vs-card>
          <vs-card @click="closeModal(2)" type="4">
            <template #title>
              <h3>EMPRESA</h3>
            </template>
            <template #text>
              <p>
                Empresa u organización con ánimo de lucro
              </p>
            </template>
            <template #img>
              <iBusiness />
            </template>
          </vs-card>
          <vs-card @click="closeModal(3)" type="4">
            <template #title>
              <h3>INSTITUCIÓN</h3>
            </template>
            <template #text>
              <p>
                Empresa u organización sin ánimo de lucro
              </p>
            </template>
            <template #img>
              <Iinstitutions />
            </template>
          </vs-card>
        </div>
      </b-card-group>
    </vs-dialog>
    <div>
      <div class="bmyCard">
        <div class="bmyCardLogo"></div>
      </div>
      <div class="bmyContent">
        <div class="bmyContentTitle">
          <img class="logoImg" loading="lazy" src="@/assets/android-chrome-512x512_green.png" />
          <h1>Register</h1>
        </div>

        <div class="bmyContentInputs">
          <div class="inputs">
            <vs-input
              label="Username"
              :color="username !== '' ? 'success' : '#fff'"
              label-placeholder="Username"
              v-model="username"
              id="username"
            >
              <template v-if="username === ''" #message-danger>
                Required
              </template>
            </vs-input>
          </div>
          <div class="inputs">
            <vs-input
              placeholder="Nombre"
              :color="nombre !== '' ? 'success' : '#fff'"
              label="Nombre"
              v-model="nombre"
              id="nombre"
            >
              <template v-if="nombre === ''" #message-danger>
                Required
              </template>
            </vs-input>
          </div>
          <div class="inputs">
            <vs-input
              v-if="this.type < 2"
              label="Apellidos"
              :color="apellidos !== '' ? 'success' : '#fff'"
              placeholder="Apellidos"
              v-model="apellidos"
              id="apellidos"
            >
              <template v-if="apellidos === ''" #message-danger>
                Required
              </template>
            </vs-input>
          </div>
          <div class="inputs">
            <vs-input
              :color="email !== '' ? 'success' : '#fff'"
              label="Email"
              placeholder="Email"
              v-model="email"
              id="email"
            >
              <template v-if="email === ''" #message-danger>
                Required
              </template>
            </vs-input>
          </div>
          <div class="inputs">
            <vs-input
              id="password"
              type="password"
              :color="password !== '' ? 'success' : '#fff'"
              v-model="password"
              label="Password"
              label-placeholder="Password"
              :progress="getProgress"
              :visiblePassword="hasVisiblePassword"
              icon-after
              @click-icon="hasVisiblePassword = !hasVisiblePassword"
            >
              <template v-if="password === ''" #message-danger>
                Required
              </template>
              <template v-if="getProgress >= 100" #message-success>
                Secure password
              </template>
            </vs-input>
          </div>
          <div class="inputs">
            <vs-input
              id="password2"
              type="password"
              :color="password === password2 ? 'success' : '#fff'"
              v-model="password2"
              label="Repeat Password"
              label-placeholder="Repeat Password"
              :visiblePassword="hasVisiblePassword"
              icon-after
              @click-icon="hasVisiblePassword = !hasVisiblePassword"
            >
              <template v-if="password2 === '' || password !== password2" #message-danger>
                {{ password2 !== password && password2 !== "" ? "Invalid password" : "Required" }}
              </template>
            </vs-input>
          </div>

          <vs-button
            :upload="username !== '' && password !== '' && email !== ''"
            block
            color="#038890"
            border
            @click="onRegister"
          >
            REGISTER
          </vs-button>
        </div>
        <div id="socialRegister">
          <div>
            <small> Ya tienes cuenta?</small>
            <a href="./login">
              Entra!
            </a>
          </div>
          <div class="socialBtns">
            <vs-button icon color="google">
              <i class="fab fa-google"></i>
            </vs-button>
            <vs-button icon color="facebook">
              <i class="fab fa-facebook"></i>
            </vs-button>
            <vs-button icon color="reddit">
              <i class="fab fa-instagram"></i>
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadingMix, noficationsDialog, authMix } from "@/common/mixin.js";
import { iPeople, iBusiness, Iinstitutions } from "@/components/ILLUSTRATIONS/";

export default {
  name: "Register",
  mixins: [loadingMix, noficationsDialog, authMix],
  components: {
    iPeople,
    iBusiness,
    Iinstitutions
  },
  computed: {
    getProgress() {
      let progress = 0;
      // at least one number
      if (/\d/.test(this.password)) {
        progress += 20;
      }
      // at least one capital letter
      if (/(.*[A-Z].*)/.test(this.password)) {
        progress += 20;
      }
      // at menons a lowercase
      if (/(.*[a-z].*)/.test(this.password)) {
        progress += 20;
      }
      // more than 5 digits
      if (this.password.length >= 6) {
        progress += 20;
      }
      // at least one special character
      if (/[^A-Za-z0-9]/.test(this.password)) {
        progress += 20;
      }
      return progress;
    }
  },
  methods: {
    closeModal(type) {
      this.modalShow = false;
      this.type = type;
    },

    async onRegister(_event) {
      this.openLoading();
      const { username, password, email, type, nombre, apellidos } = this;
      try {
        const cred = await this.firebaseRegister(email, password);
        const body = {
          username,
          email,
          type,
          nombre,
          apellidos,
          id: cred.user.uid
        };
        await this.firebaseSaveUser(cred.user.uid, body, true);
        if (cred.user) {
          if (!cred.user.emailVerified) {
            this.firebaseSendVerification();
          }
          localStorage.setItem("user", cred.user.uid);
          this.$router.push("/login");
        }
      } catch (error) {
        console.log(error);
        if (error.code === "auth/email-already-in-use") {
          let text = `Es posible que el nick ${username} o el email ${email} ya esten registrados!`;
          this.notificateLoginFail("Error al registrar Usuario", text);
        }
      }
      this.closeLoading();
    }
  },
  mounted() {},
  data() {
    return {
      username: "",
      password: "",
      password2: "",
      email: "",
      modalShow: true,
      type: 1,
      nombre: "",
      apellidos: "",
      hasVisiblePassword: false
    };
  }
};
</script>

<style scoped>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@600&display=swap");

.whiteLogo {
  top: 85px;
  width: 100%;
  position: relative;
  left: 0;
}

.inputs {
  justify-content: center;
  display: grid;
  margin-bottom: 1.5rem;
}

.inputs:last-child {
  margin-bottom: 0;
}

/* // Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */
@media (max-width: 575px) {
  .bmyCard {
    width: 100%;
    max-width: none !important;
  }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .bmyCard {
    width: 100%;
    max-width: none !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .header {
    margin: 5rem;
    margin-bottom: 3rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.previewImg {
  height: 50px;
  width: 50px;
}

.bmyCard {
  font-family: "Cairo", sans-serif;
  max-width: 500px;
  margin: auto;
  background: linear-gradient(0, #900358, #f83255);
  color: #038890;
  height: 20%;
  max-height: 250px;
  min-height: 200px;
}

.bmyCardLogo {
  background-image: url("../assets/white_transparent.png");
  background-size: 100%;
  background-position-y: 80px;
  width: 100%;
  height: 250px;
}

.bmyContent {
  color: #038890;
  max-width: 500px;
  margin: auto;
  background: white;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
}

.bmyContentTitle {
  display: flex;
}

.bmyContentInputs {
  margin-top: 2rem;
}

.logoImg {
  height: 50px;
  width: 50px;
}

.socialBtns {
  display: flex;
}

#socialRegister {
  display: flex;
  justify-content: space-between;
}
</style>
